
import {
  IMenuSearch,
  IRole,
  ISearchPost,
  ISearchUser,
  IBlogList,
} from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { search, searchUser } from '@/api/search';
import { getUidList, getSearchMenuList } from '@/api/menu';
import { getRoleList } from '@/api/role';
import { getBlogList } from '@/api/myBlog';
import MyBlog from '@/components/blog/index.vue';

@Component({
  name: 'Search',
  components: {
    MyBlog,
  },
})
export default class extends Vue {
  mounted() {
    this.init().then(() => {
      if (this.$route.query.searchValue) {
        this.search.searchValue = String(this.$route.query.searchValue);
        this.handleSearch();
      }
    });
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private searchedValue: String = '';

  private search = {
    menuUid: '',
    searchValue: '',
  };

  private searchMenuList: IMenuSearch[] = [];

  private boardMenuList: IMenuSearch[] = [];

  private searchPostList: ISearchPost[] = [];

  private searchUserList: ISearchUser[] = [];

  private blogList: IBlogList[] = [];

  private menuUidList: string[] = [];

  private roleUidList: string[] = [];

  private blogVisible = false;

  private blogNickName = '';

  private init() {
    return new Promise((resolve) => {
      getUidList().then((res) => {
        this.menuUidList = res.data;
      });
      getRoleList().then((res) => {
        res.data.forEach((r: IRole) => {
          const roleMenu: IMenuSearch = {
            menuUid: r.roleCode,
            menuName: r.roleName,
          };
          this.searchMenuList.push(roleMenu);
          this.roleUidList.push(r.roleCode);
        });
        getSearchMenuList().then((response) => {
          this.boardMenuList = response.data;
          this.searchMenuList = this.searchMenuList.concat(response.data);
          resolve('');
        });
      });
    });
  }

  private handleSearch() {
    if (this.search.searchValue.trim()) {
      this.searchPostList = [];
      const loading = this.$loading.show();
      searchUser(this.search).then((res) => {
        this.searchedValue = this.search.searchValue;
        this.searchUserList = res.data;
      });
      if (this.search.menuUid === '') {
        this.boardMenuList.forEach((menu: IMenuSearch) => {
          this.search = {
            ...this.search,
            menuUid: menu.menuUid,
          };
          search(this.search).then((res) => {
            this.searchedValue = this.search.searchValue;
            this.searchPostList = this.searchPostList.concat(res.data);
          });
        });
        this.search.menuUid = '';
      } else {
        search(this.search).then((res) => {
          this.searchedValue = this.search.searchValue;
          this.searchPostList = this.searchPostList.concat(res.data);
        });
      }
      loading.hide();
    } else {
      alert('검색어를 입력해주세요.');
    }
  }

  private getRouter(idx: number) {
    const role = this.searchUserList[idx];
    const roleIndex = this.roleUidList.indexOf(role.roleCode);
    const router = { name: '', params: { menuUid: this.menuUidList[idx] }, query: { searchValue: this.searchedValue } };
    if (roleIndex === 0) router.name = 'Makerspace';
    if (roleIndex === 1) router.name = 'Startup';
    if (roleIndex === 2) router.name = 'Support';
    if (roleIndex === 3) router.name = 'Invest';
    return router;
  }

  private getDetailRouter(idx: number, index: number) {
    /* eslint-disable */
    const info = this.searchUserList[idx];
    const router = {
      name: '',
      params: { menuUid: this.menuUidList[idx], nickname: '', uid: '' },
      query: {
        searchValue: this.searchedValue,
      },
    };
    const roleIndex = this.roleUidList.indexOf(info.roleCode);
    if (roleIndex === 0) {
      router.name = 'MakerspaceDetail',
      router.params.nickname = info.userList[index].nickName;
    }
    if (roleIndex === 1) {
      router.name = 'StartupDetail',
      router.params.nickname = info.userList[index].nickName;
    }
    if (roleIndex === 2) {
      router.name = 'SupportDetail',
      router.params.uid = info.userList[index].userUid;
    }
    if (roleIndex === 3) {
      router.name = 'InvestDetail',
      router.params.uid = info.userList[index].userUid;
    }
    return router;
    /* eslint-enable */
  }

  private handleClickBlog(nickName: string) {
    this.blogNickName = nickName;
    this.blogVisible = !this.blogVisible;
  }
}
